import { afterNextRender, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Utils } from './shared/utils/utils';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'azh-front-beneficiaries-angular';
  
  constructor(
		private utils: Utils
	) { 
    afterNextRender(() => {

      setInterval(() => {
        this.utils.saveAudit();
      }, (300000 / 2));
    });
  }
}
