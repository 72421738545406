import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JsonResult } from '../../shared/interfaces/json-result.interface';
import { HttpClientModule } from '@angular/common/http';
import { AzLoadersModule } from '../../shared/az-loaders';
import { Color, NgxChartsModule, ScaleType } from '@swimlane/ngx-charts';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { PatientService } from '../services/patient.service';
import { LastMeditionA, DataExtra } from '../../shared/interfaces/patient.interface';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import moment from 'moment';
import 'moment/locale/es';
import { ValidatorsUtils } from '../../shared/utils/validators.util';
import { AuthService } from '../../login/services/auth.services';
import { Utils } from '../../shared/utils/utils';
@Component({
  selector: 'app-screen-weight',
  standalone: true,
  imports: [HttpClientModule, AzLoadersModule, NgxChartsModule, MatTabsModule, InfiniteScrollModule],
  templateUrl: './screen-weight.component.html',
  styleUrls: ['./screen-weight.component.css'],
  providers: []
})
export class ScreenWeightComponent implements OnInit {
  stateChart: any = {
    chart1: false,
    chart2: false,
  }

  itemList: LastMeditionA[] = [];
  selected: number = 0;
  token!: string;
  loading: boolean = true;
  loadingScroll: boolean = true;
  itemsPerPage: number = 300;
  currentPage: number = 0;
  itemsTotal: number = 0;
  weightData: any = [
    {
      name: "Peso (kg)",
      type: "body_weight",
      min: 20,
      max: 220
    },
    {
      name: "IMC",
      type: "imc",
      min: 10,
      max: 40
    },
    {
      name: "Grasa Corporal",
      type: "body_fat",
      min: 0,
      max: 100
    },
    {
      name: "Masa múscular",
      type: "body_muscle_mass",
      min: 0,
      max: 120
    },
    {
      name: "Agua corporal",
      type: "body_water",
      min: 0,
      max: 100
    },
    {
      name: "Masa ósea",
      type: "body_bone_mass",
      min: 0,
      max: 120
    },
  ];
  weightDataYear: any = [
    {
      name: "Peso (kg)",
      type: "body_weight",
      min: 20,
      max: 220
    },
    {
      name: "IMC",
      type: "imc",
      min: 10,
      max: 40
    },
    {
      name: "Grasa Corporal",
      type: "body_fat",
      min: 0,
      max: 100
    },
    {
      name: "Masa múscular",
      type: "body_muscle_mass",
      min: 0,
      max: 120
    },
    {
      name: "Agua corporal",
      type: "body_water",
      min: 0,
      max: 100
    },
    {
      name: "Masa ósea",
      type: "body_bone_mass",
      min: 0,
      max: 120
    },
  ];
  multi: any = {
    "body_weight": [],
    "imc": [],
    "body_fat": [],
    "body_muscle_mass": [],
    "body_water": [],
    "body_bone_mass": []
  };
  loadingService: any = {
    "body_weight": true,
    "imc": true,
    "body_fat": true,
    "body_muscle_mass": true,
    "body_water": true,
    "body_bone_mass": true,
    "body_weight_year": true,
    "imc_year": true,
    "body_fat_year": true,
    "body_muscle_mass_year": true,
    "body_water_year": true,
    "body_bone_mass_year": true
  };
  multiYear: any = {
    "body_weight": [],
    "imc": [],
    "body_fat": [],
    "body_muscle_mass": [],
    "body_water": [],
    "body_bone_mass": []
  };

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  timeline: boolean = true;

  colorScheme: Color = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
    name: '',
    selectable: false,
    group: "linear" as ScaleType
  };

	timeout: number = 100;

  constructor(
    private activatedRouter: ActivatedRoute,
    public authService: AuthService,
    private patienService: PatientService,
    private validatorsUtils: ValidatorsUtils,
    private utils: Utils
  ) {
    moment.locale('es-ES');
  }

  ngOnInit(): void {
    this.activatedRouter.queryParams.subscribe((trae) => {
      let { tokenApp } = trae;
      this.token = tokenApp
      if(!tokenApp && this.authService._token){
        this.token = this.authService._token;
      }
      if (this.token) {
        this.utils.saveDataAudit("Exitoso",`Accedio a la ventana historial de Peso`);
        this.getDataPatient()
      }
    });
  }

  getDataPatient(load = true) {
    this.loading = load;
    for (let i = 0; i < this.weightData.length; i++) {
      this.getDataDailyMeasurement(this.weightData[i].type);
      this.getDataYearMeasurement(this.weightData[i].type);
    }
    this.getHistoryMeasurements(true);
    this.loading = false;
  }

  getDataDailyMeasurement(type: string) {
    this.loadingService[type] = true;
    this.patienService.getDataDailyMeasurement(type, this.token).subscribe((trae: JsonResult) => {
      let response: any[] = trae.retObject.data;
      let dataGrafic: any = [];
      for (let index = 0; index < response.length; index++) {
        if (response[index].value > 0)
          dataGrafic.push({ "name": response[index].date, "value": response[index].value });
      }
      if (!dataGrafic.length) {
        dataGrafic.push({ "name": trae.retObject.start_format, "value": 0 }, { "name": trae.retObject.end_format, "value": 0 });
      }
      this.initCharts(type, dataGrafic, 1);
      setTimeout(() => {
        this.stateChart.chart1 = true;
        this.loadingService[type] = false;
      }, this.timeout);
    }, (error:any) => { 
      this.utils.saveDataAudit("Error",`Error al obtener data del grafico diario del historial de ${type}`,error.error);
      this.loadingService[type] = false; 
    });
  }

  getDataYearMeasurement(type: string) {
    this.loadingService[type+'_year'] = true;
    this.patienService.getDataYearMeasurement(type, this.token).subscribe((trae: JsonResult) => {
      let response: any[] = trae.retObject;
      if (!response.length) {
        let s = moment().add(-12, "months").format("MMM YYYY");
        let e = moment().format("MMM YYYY")
        response.push({
          "name": "",
          "series": [
            {
              "name": s.charAt(0).toUpperCase() + s.slice(1).replace(".", ""),
              "value": 0,
              "label": ""
            },
            {
              "name": e.charAt(0).toUpperCase() + e.slice(1).replace(".", ""),
              "value": 0,
              "label": ""
            }
          ]
        });
      }
      this.initCharts(type, response, 2);
      this.loadingService[type+'_year'] = false;
    }, (error:any) => { 
      this.utils.saveDataAudit("Error",`Error al obtener data del grafico anual del historial de ${type}`,error.error);
      this.loadingService[type+'_year'] = false; 
    });
  }
  tabSelectedParent(e: MatTabChangeEvent) {
    if (e.index == 1) {
      setTimeout(() => {
        this.stateChart.chart2 = true;
      }, this.timeout);
    }
  }
  initCharts(array: string, data: any, type: number) {
    if (type == 1) {
      this.multi[array] = [{
        "name": "",
        "series": data
      }];
      this.validatorsUtils.calcMinAndMAx(this.weightData, array, data);
    } else {
      this.multiYear[array] = data;
      this.validatorsUtils.calcMinAndMAx(this.weightDataYear, array, data[0].series);
    }
  }

  onScroll() {
    if (!this.loadingScroll) {
      this.currentPage++;
      if (this.itemList.length < this.itemsTotal)
        this.getHistoryMeasurements();
    }
  }

  getHistoryMeasurements(loading: boolean = false) {
    this.loadingScroll = true;
    if (loading)
      this.itemList = [];
    this.patienService.getHistoryMeasurements(this.token, 'body_weight', this.currentPage, this.itemsPerPage)
      .subscribe((res: JsonResult) => {
        let response = res.retObject;
        this.itemList = this.itemList.concat(response.data);
        this.itemsTotal = response.total;
        this.loadingScroll = false;
      }, (error: any) => {
        this.utils.saveDataAudit("Error",`Error al obtener el listado del historial de Peso`,error.error);
        this.loadingScroll = false;
        console.error(error);
      });
  }

  searchItemExtra(id: number, items: DataExtra[]): DataExtra | null {
    for (const item of items) {
      if (item.id == id) {
        return item;
      }
    }
    return null;
  }
}
