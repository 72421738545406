import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject, NO_ERRORS_SCHEMA, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ValidatorsUtils } from "../../../shared/utils/validators.util";
import { HomeService } from "../../services/home.services";
import moment from "moment";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { HttpClientModule } from "@angular/common/http";
import { Utils } from "../../../shared/utils/utils";

@Component({
    selector: "app-weight",
    templateUrl: "./weight.component.html",
    standalone: true,
    imports: [MatDialogModule, CommonModule, FormsModule, ReactiveFormsModule, MatSnackBarModule, HttpClientModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    providers: []
})
export class WeightComponent implements OnInit {

    exMeasurement: string = "^[0-9]{1,3}(\\.[0-9]{1,2})?$";
    form!: FormGroup;
    loadService: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private modalRef: MatDialogRef<WeightComponent>,
        private fb: FormBuilder,
        public validatorUtils: ValidatorsUtils,
        private homeService: HomeService,
        private _snackBar: MatSnackBar,
        private utils: Utils
    ) { }

    ngOnInit(): void {
        this.utils.saveDataAudit("Exitoso",`Accedio a la ventana ingresar medición de Peso`);
        this.initForm();
    }

    initForm() {
        this.form = this.fb.group({
            weight: ['', [Validators.required, Validators.min(1), Validators.pattern(this.exMeasurement)]],
            bodyFat: [0, [Validators.min(0), Validators.pattern(this.exMeasurement)]],
            muscleMass: [0, [Validators.min(0), Validators.pattern(this.exMeasurement)]],
            bodyWater: [0, [Validators.min(0), Validators.pattern(this.exMeasurement)]],
            boneMass: [0, [Validators.min(0), Validators.pattern(this.exMeasurement)]],
            bmi: [0],
            commet: ['', [Validators.minLength(3)]]
        });
    }

    getBmi(): void {
        let weight = this.form.get('weight')?.value;
        let height = this.data.data.altura;
        if (weight == ".") {
            return;
        }
        this.form.patchValue({
            bmi: this.validatorUtils.calcIMC(weight, height),
            bodyFat: this.validatorUtils.calcBodyFat({ age: this.data.data.age, weight: weight, height: height }),
            muscleMass: this.validatorUtils.calcBodyMassMuscle({ age: this.data.data.age, weight: weight, height: height, genre: this.data.data.genero }),
            bodyWater: this.validatorUtils.calcBodyWater({ weight: weight, genre: this.data.data.genero }),
            boneMass: this.validatorUtils.calcBodyMassBone({ weight: weight, genre: this.data.data.genero })
        });
    }

    submit() {
        let info = this.form;
        let utc: string | number = moment().format('Z');
        utc = Number(utc.replace(':', ''));
        if (utc > 0) {
            utc = utc * -1;
        }
        let dataOptional: any = [];
        if (info.get('bodyFat')?.value > 0) {
            dataOptional.push({
                "unit": "percent",
                "type": "body_fat",
                "origin": "manual",
                "value": info.get('bodyFat')?.value
            });
        }
        if (info.get('muscleMass')?.value > 0) {
            dataOptional.push({
                "origin": "manual",
                "value": info.get('muscleMass')?.value,
                "type": "body_muscle_mass",
                "unit": "kg"
            });
        }
        if (info.get('bodyWater')?.value > 0) {
            dataOptional.push({
                "value": info.get('bodyWater')?.value,
                "unit": "percent",
                "origin": "manual",
                "type": "body_water"
            });
        }
        if (info.get('boneMass')?.value > 0) {
            dataOptional.push({
                "type": "body_bone_mass",
                "unit": "kg",
                "origin": "manual",
                "value": info.get('boneMass')?.value
            });
        }

        let data: any = {
            "category": "daily",
            "start_time": moment().format("YYYY-MM-DD HH:mm:ss"),
            "end_time": moment().format("YYYY-MM-DD HH:mm:ss"),
            "metrics": [
                ...dataOptional,
                {
                    "origin": "manual",
                    "type": "body_weight",
                    "unit": "kg",
                    "value": info.get('weight')?.value
                },
                {
                    "origin": "manual",
                    "unit": "kg/m2",
                    "type": "bmi",
                    "value": info.get('bmi')?.value
                }
            ],
            "source": {
                "type": "manual",
                "device": {
                    "model": "manual",
                    "manufacturer": "manual"
                }
            },
            "uid": this.data.data.usuariovalidic,
            "type": "Measurements",
            "optional_note": info.get('commet')?.value,
            "utc_offset": utc
        }
        this.loadService = true;
        this.homeService.postMeasurements(data).subscribe(() => {
            this.utils.saveDataAudit("Exitoso",`Medición de peso guardada correctamente`);
            this._snackBar.open("Medición de peso creada exitosamente", "Ok");
            this.loadService = false;
            this.closeModal(true);
        }, (error: any) => {
            this.utils.saveDataAudit("Error",`Error al guardar la medición de peso`,error.error);
            this.loadService = false;
        });
    }

    closeModal(value: boolean = false) {
        this.utils.saveDataAudit('Exitoso',`Salio de la ventana medición de Peso.`);
        this.modalRef.close(value);
    }
}