import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JsonResult } from '../../shared/interfaces/json-result.interface';
import { HttpClientModule } from '@angular/common/http';
import { AzLoadersModule } from '../../shared/az-loaders';
import { Color, NgxChartsModule, ScaleType } from '@swimlane/ngx-charts';
import { MatTabsModule } from '@angular/material/tabs';
import { PatientService } from '../services/patient.service';
import { LastMeditionA, DataExtra } from '../../shared/interfaces/patient.interface';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import moment from 'moment';
import { ValidatorsUtils } from '../../shared/utils/validators.util';
import { AuthService } from '../../login/services/auth.services';
import { Utils } from '../../shared/utils/utils';

@Component({
  selector: 'app-screen-presion',
  standalone: true,
  imports: [HttpClientModule, AzLoadersModule, NgxChartsModule, MatTabsModule, InfiniteScrollModule],
  templateUrl: './screen-presion.component.html',
  styleUrls: ['./screen-presion.component.css'],
  providers: []
})
export class ScreenPresionComponent implements OnInit {
  stateChart: any = {
    chart1: false,
    chart2: false,
    chart3: false,
    chart4: false,
    chart5: false,
    chart6: false
  }
  rangeChart: any = {
    chart1: {
      min: 60,
      max: 120
    },
    chart2: {
      min: 60,
      max: 120
    },
    chart3: {
      min: 60,
      max: 90
    },
    chart4: {
      min: 60,
      max: 120
    },
    chart5: {
      min: 60,
      max: 120
    },
    chart6: {
      min: 60,
      max: 90
    }
  }
  start: string = moment().add(-6, "days").format("YYYY-MM-DD");
  end: string = moment().format("YYYY-MM-DD");
  itemList: LastMeditionA[] = [];
  selected: number = 0;
  token!: string;
  loading: boolean = true;
  multiTime: any[] = [];
  multiDay: any[] = [];
  multiDayYear: any[] = []
  multi: any[] = [];
  multiYear: any[] = [];
  multiTimeYear: any[] = [];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  timeline: boolean = true;

  maxRadius: number = 5;
  minRadius: number = 5;
  yScaleMin: number = 60;
  yScaleMax: number = 120;

  colorScheme: Color = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
    name: '',
    selectable: false,
    group: "linear" as ScaleType
  };

  customColors = [
    { name: 'inappropriate', value: "#bb3200" },
    { name: 'low', value: '#bb3200' },
    { name: 'normal', value: '#69b09f' },
    { name: 'hight', value: '#f6bd53' },
    { name: 'stage1', value: '#ff46033f' },
    { name: 'stage2', value: '#ff46039f' },
    { name: 'none', value: 'transparent' },
  ];

  types: any = {
    'low': "Crisis hipertensiva",
    'normal': "Normal",
    'hight': 'Elevada',
    'stage1': "Hipertensión etapa 1",
    'stage2': "Hipertensión etapa 2"
  }
  typesPulse: any = {
    'inappropriate': "Inadecuado",
    'low': "Inadecuado",
    'normal': "Normal"
  }
  customTicksY = [60, 120];
  customTicksX = ["00", "04", "08", "12", "16", "20", "23"];
  loadingScroll: boolean = true;
  itemsPerPage: number = 300;
  currentPage: number = 0;
  itemsTotal: number = 0;
  constructor(
    private activatedRouter: ActivatedRoute,
    public authService: AuthService,
    private patienService: PatientService,
    private validatorsUtils: ValidatorsUtils,
    private utils: Utils
  ) { }

  ngOnInit(): void {
    this.activatedRouter.queryParams.subscribe((trae) => {
      let { tokenApp } = trae;
      this.token = tokenApp
      if(!tokenApp && this.authService._token){
        this.token = this.authService._token;
      }
      if (this.token) {
        this.utils.saveDataAudit("Exitoso",`Accedio a la ventana historial de Presión arterial`);
        this.getDataPatient()
      }
    });
  }

  getDataPatient(load = true) {
    this.loading = load;
    this.getDataDailyMeasurement("presion_arterial", "chart1", "multiDay");
    this.getDataTimeMeasurement("presion_arterial", "chart2", "multiTime");
    this.getDataDailyMeasurement("pulse", "chart3", "multi");
    this.getHistoryMeasurements(true);
    this.loading = false;
  }

  getDataDailyMeasurement(type: string, chart: string, mul: "multi" | "multiDay") {
    this.patienService.getDataDailyMeasurement(type, this.token, `?start=${this.start}&end=${this.end}`).subscribe((trae: JsonResult) => {
      let response = trae.retObject.data;
      if (chart == "chart3") {
        let dataGraficNormal: any = [];
        let dataGraficLow: any = [];
        for (let index = 0; index < response.normal.length; index++) {
          dataGraficNormal.push({ "name": response.normal[index].date, "value": response.normal[index].value });
          dataGraficLow.push({ "name": response.inappropriate[index].date, "value": response.inappropriate[index].value });
        }
        this.validatorsUtils.calcMinAndMAx(this.rangeChart, chart, dataGraficNormal.concat(dataGraficLow), 2, true);

        if (!dataGraficNormal.length && !dataGraficLow.length) {
          dataGraficNormal.push({ "name": trae.retObject.start_format, "value": 0 }, { "name": trae.retObject.end_format, "value": 0 });
          dataGraficLow.push({ "name": trae.retObject.start_format, "value": 0 }, { "name": trae.retObject.end_format, "value": 0 });
        }
        this[mul] = [{ "name": "normal", "series": dataGraficNormal }, { "name": "low", "series": dataGraficLow }];
        this.stateChart[chart] = true;
      } else {
        let dataGrafic: any = [];
        for (let index = 0; index < response.length; index++) {
          dataGrafic.push({
            "name": response[index].labelHealth || 'none', series: [
              {
                "name": response[index].date,
                "x": response[index].date,
                y: (response[index].value > 0) ? response[index].value : 0,
                r: 0,
              }]
          });
        }
        if (dataGrafic.length) {
          this.validatorsUtils.calcMinAndMAx(this.rangeChart, chart, response, 2, true);
          this[mul] = dataGrafic
          this.stateChart[chart] = true;
        }
      }
    }, (error: any) => { 
      this.utils.saveDataAudit("Error",`Error al obtener data del grafico(${mul}) del historial de ${type}`,error.error);
    });
  }
  getDataTimeMeasurement(type: string, chart: string, mul: "multiTime" | "multiTimeYear") {
    this.patienService.getDataTimeMeasurement(type, this.token, `?start=${this.start}&end=${this.end}`).subscribe((trae: JsonResult) => {
      let response = trae.retObject.data.presion_arterial;
      let dataGrafic: any = [];
      for (let index = 0; index < response.length; index++) {
        dataGrafic.push({
          "name": response[index].labelHealth || "none", series: [
            {
              "name": `${response[index].time}`,
              "x": `${response[index].time}`,
              y: response[index].value,
              r: 0,
            }]
        });
      }
      if (dataGrafic.length) {
        this.validatorsUtils.calcMinAndMAx(this.rangeChart, chart, response, 2, true);
        this[mul] = dataGrafic
        this.stateChart[chart] = true;
      }
    }, (error:any) => {
      this.utils.saveDataAudit("Error",`Error al obtener data del grafico(${mul}) del historial de ${type}`,error.error);
     });
  }

  getDataYearMeasurement(type: string, chart: string, mul: "multiDayYear" | "multiYear") {
    this.stateChart[chart] = false;
    this.patienService.getDataYearMeasurement(type, this.token).subscribe((trae: JsonResult) => {
      let response: any[] = trae.retObject;
      let array: any[] = [];
      for (const iterator of response) {
        array = array.concat(iterator.series)
      }
      this.validatorsUtils.calcMinAndMAx(this.rangeChart, chart, array, 2, true);
      if (!response.length) {
        let date_format = moment().add(-12, "months").format("MMM YYYY").replace(".", "")
        date_format = date_format.charAt(0).toUpperCase() + date_format.slice(1)
        let date_format_end = moment().format("MMM YYYY").replace(".", "")
        date_format_end = date_format_end.charAt(0).toUpperCase() + date_format_end.slice(1)
        if (chart == "chart4") {
          response.push({
            "name": "low",
            "series": [
              {
                "name": date_format,
                "label": "",
                "x": date_format,
                "y": 0,
                "r": 0
              }
            ]
          },
            {
              "name": "low",
              "series": [
                {
                  "name": date_format_end,
                  "label": "",
                  "x": date_format_end,
                  "y": 0,
                  "r": 0
                }
              ]
            });
        } else {
          response = [
            {
              "name": "inappropriate",
              "series": [
                {
                  "name": date_format,
                  "value": 0
                },
                {
                  "name": date_format_end,
                  "value": 0
                }
              ]
            },
            {
              "name": "normal",
              "series": [
                {
                  "name": date_format,
                  "value": 0
                },
                {
                  "name": date_format_end,
                  "value": 0
                }
              ]
            }
          ];
        }
      }
      this.stateChart[chart] = true;
      this[mul] = response
    }, (error: any) => { 
      this.utils.saveDataAudit("Error",`Error al obtener data del grafico(${mul}) del historial de ${type}`,error.error);
    });
  }

  searchItemExtra(id: number, items: DataExtra[]): DataExtra | null {
    for (const item of items) {
      if (item.id == id) {
        return item;
      }
    }
    return null;
  }
  extraerDespliegue(despliegue: string): string {
    if (despliegue.split(" ")[1]) {
      return despliegue.split(" ")[1].substring(0, 3).toLowerCase()
    } else {
      return despliegue.substring(0, 5).toLowerCase()
    }
  }

  onScroll() {
    if (!this.loadingScroll) {
      this.currentPage++;
      if (this.itemList.length < this.itemsTotal)
        this.getHistoryMeasurements();
    }
  }
  getHistoryMeasurements(loading: boolean = false) {
    this.loadingScroll = true;
    if (loading)
      this.itemList = [];
    this.patienService.getHistoryMeasurements(this.token, 'presion_arterial', this.currentPage, this.itemsPerPage)
      .subscribe((res: JsonResult) => {
        let response = res.retObject;
        this.itemList = this.itemList.concat(response.data);
        this.itemsTotal = response.total;
        this.loadingScroll = false;
      }, (error: any) => {
        this.utils.saveDataAudit("Error",`Error al obtener el listado del historial de Presión arterial`,error.error);
        this.loadingScroll = false;
        console.error(error);
      });
  }
  changeFilters(index: number): void {
    this.selected = index;
    switch (index) {
      case 0:
        this.start = moment().add(-6, "days").format("YYYY-MM-DD")
        this.end = moment().format("YYYY-MM-DD")
        this.selectTab();
        break;
      case 1:
        this.start = moment().add(-30, "days").format("YYYY-MM-DD")
        this.end = moment().format("YYYY-MM-DD")
        this.selectTab();
        break;
      case 2:
        this.start = moment().add(-12, "months").format("YYYY-MM-DD")
        this.end = moment().format("YYYY-MM-DD")
        this.stateChart.chart4 = false;
        this.getDataYearMeasurement("presion_arterial", "chart4", "multiDayYear");
        this.stateChart.chart5 = false;
        this.getDataTimeMeasurement("presion_arterial", "chart5", "multiTimeYear");
        this.stateChart.chart6 = false;
        this.getDataYearMeasurement("pulse", "chart6", "multiYear");
        break;
    }
  }
  selectTab(): void {
    this.rangeChart = {
      chart1: {
        min: 60,
        max: 120
      },
      chart2: {
        min: 60,
        max: 120
      },
      chart3: {
        min: 60,
        max: 90
      },
      chart4: {
        min: 60,
        max: 120
      },
      chart5: {
        min: 60,
        max: 120
      },
      chart6: {
        min: 60,
        max: 90
      }
    }
    this.stateChart.chart1 = false;
    this.getDataDailyMeasurement("presion_arterial", "chart1", "multiDay");
    this.stateChart.chart2 = false;
    this.getDataTimeMeasurement("presion_arterial", "chart2", "multiTime");
    this.stateChart.chart3 = false;
    this.getDataDailyMeasurement("pulse", "chart3", "multi");
  }
}
