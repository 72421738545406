import { Component, OnInit } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxCaptchaModule, ReCaptchaV3Service } from 'ngx-captcha';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.services';
import { JsonResult } from '../shared/interfaces/json-result.interface';
import { HttpClientModule } from '@angular/common/http';
import { Utils } from '../shared/utils/utils';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, NgxCaptchaModule, MatSnackBarModule, HttpClientModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: []
})
export class LoginComponent implements OnInit {
  public readonly siteKey: string = "6Ld1mWoeAAAAACqxpabE4LA_1lOYEiK1Ak5z968K";
  form!: FormGroup;

  loadService: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private utils: Utils,
    private formBuilder: FormBuilder,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private _snackBar: MatSnackBar
  ) { }

  get f(): { [key: string]: AbstractControl } | any {
    return this.form.controls;
  }

  ngOnInit(): void {
    if (this.authService._isLogin == true) {
      this.router.navigateByUrl('/');
    }
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      rut: ["", [Validators.required, Validators.pattern("[0-9]{1,10}(-)?[0-9Kk]{1}")]],
      hash: ["", Validators.required],
    });


  }
  ngAfterViewInit() {
    this.reloadReCaptcha();
  }
  reloadReCaptcha(): void {
    this.reCaptchaV3Service.execute(this.siteKey, 'login', (token) => {
      this.form.get("hash")?.setValue(token);
    }, {
      useGlobalDomain: false
    });
  }
  login() {
    this.loadService = true;

    this.authService.login(this.form.value).subscribe((trae: JsonResult) => {
      let { token } = trae.retObject;
      delete trae.retObject["token"];
      this.authService.setAuthenticated(token);

      localStorage.setItem('userinfo', JSON.stringify(trae.retObject));
      this.utils.saveDataAudit("Exitoso","Inicio de sesión");
      this.router.navigateByUrl("/");
      this.loadService = false;

    }, (err: any) => {
      if (err.status === 500 || err.status === 502) {
        this._snackBar.open("Error inesperado", "Cerrar");
      }
      this.loadService = false;
      this.reloadReCaptcha();
    });
  }
}
