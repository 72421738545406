import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject, NO_ERRORS_SCHEMA, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ValidatorsUtils } from "../../../shared/utils/validators.util";
import { HomeService } from "../../services/home.services";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import moment from "moment";
import { HttpClientModule } from "@angular/common/http";
import { Utils } from "../../../shared/utils/utils";

@Component({
    selector: "app-blood-pressure",
    templateUrl: "./blood-pressure.component.html",
    standalone: true,
    imports: [MatDialogModule, CommonModule, FormsModule, ReactiveFormsModule, MatSnackBarModule, HttpClientModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    providers: []
})
export class BloodPressureComponent implements OnInit {

    exMeasurement: string = "^[0-9]{1,3}(\\.[0-9]{1,2})?$";
    form!: FormGroup;
    loadService: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private modalRef: MatDialogRef<BloodPressureComponent>,
        private fb: FormBuilder,
        public validatorUtils: ValidatorsUtils,
        private homeService: HomeService,
        private _snackBar: MatSnackBar,
        private utils: Utils
    ) { }

    ngOnInit(): void {
        this.utils.saveDataAudit("Exitoso",`Accedio a la ventana ingresar medición de Presión arterial`);
        this.initForm();
    }

    initForm() {
        this.form = this.fb.group({
            systolic: ['', [Validators.required, Validators.min(1), Validators.pattern(this.exMeasurement)]],
            diastolic: ['', [Validators.required, Validators.min(1), Validators.pattern(this.exMeasurement)]],
            pulse: ['', [Validators.required, Validators.min(1), Validators.pattern(this.exMeasurement)]],
            commet: ['', [Validators.minLength(3)]]
        });
    }

    submit() {
        let info = this.form;
        let utcZ: string | number = moment().format('Z');
        utcZ = Number(utcZ.replace(':', ''));
        if (utcZ > 0) {
            utcZ = utcZ * -1;
        }
        let dateTime = moment().format("YYYY-MM-DD HH:mm:ss");

        let item: any = {
            "uid": this.data.data.usuariovalidic,
            "type": "Measurements",
            "optional_note": info.get('commet')?.value,
            "utc_offset": utcZ,
            "category": "daily",
            "start_time": dateTime,
            "end_time": dateTime,
            "metrics": [
                {
                    "origin": "manual",
                    "type": "systolic",
                    "unit": "mmHg",
                    "value": info.get('systolic')?.value
                },
                {
                    "origin": "manual",
                    "unit": "mmHg",
                    "type": "diastolic",
                    "value": info.get('diastolic')?.value
                },
                {
                    "origin": "manual",
                    "unit": "bpm",
                    "type": "pulse",
                    "value": info.get('pulse')?.value
                }
            ],
            "source": {
                "type": "manual",
                "device": {
                    "model": "manual",
                    "manufacturer": "manual"
                }
            },
        }
        this.loadService = true;
        this.homeService.postMeasurements(item).subscribe(() => {
            this.utils.saveDataAudit("Exitoso",`Medición de presión arterial guardada correctamente`);
            this._snackBar.open("Medición de presión arterial creada exitosamente", "Ok");
            this.loadService = false;
            this.closeModal(true);
        }, (error: any) => {
            this.utils.saveDataAudit("Error",`Error al guardar la medición de presión arterial`,error.error);
            this.loadService = false;
        });
    }

    closeModal(value: boolean = false) {
        this.utils.saveDataAudit('Exitoso',`Salio de la ventana medición de Presión arterial.`);
        this.modalRef.close(value);
    }
}