import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private headers = new HttpHeaders({ 'Accept': 'application/json', 'Authorization': 'Bearer ' });
    private url: string;
    public get _isLogin(): boolean {
        let _t: boolean | any = typeof localStorage == 'undefined' ? false : localStorage.getItem(`is_loggin`);

        if (_t === null) {
            _t = false;
        } else {
            _t = JSON.parse(_t);
        }

        return _t;
    }
    public get _token(): string {
        let _t: string | any = typeof localStorage == 'undefined' ? null : localStorage.getItem('access_token')!;
        return _t;
    }
    public get _getuserInfo(): { idpaciente: number, iduser: number, email: String } {
        let _t: string | any = typeof localStorage == 'undefined' ? '{}' : localStorage.getItem('userinfo')!;
        return JSON.parse(_t);
    }
    constructor(
        private _http: HttpClient,
        private router: Router
    ) {
        this.url = `${environment.URL_FRONT}`;
    }

    login(data: { rut: String, hash: String }): any {
        data.rut = data.rut.replace(/-/g, '')
        let _url = `${this.url}ext/seguimiento/webresources/services.patients/buscar`;
        return this._http.post(_url, data, { headers: this.headers });
    }

    setAuthenticated(token: String) {
        localStorage.setItem(`is_loggin`, JSON.stringify(true));
        localStorage.setItem(`access_token`, `${token}`);
    }

    logout(redirect_login: boolean) {

        localStorage.removeItem(`is_loggin`);
        localStorage.removeItem(`access_token`);
        localStorage.removeItem(`userinfo`);

        if (redirect_login) {
            this.router.navigateByUrl('/auth/login');
        }
    }

    postAudit(data: any) {
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this._token}`
        });
        let serviceURL = `${this.url}seguimiento/webresources/services.audit/addMassive`;
        localStorage.removeItem('jsonAuditAzhBeneficiaries');
        return this._http.post<any>(serviceURL, data, { headers: this.headers });
    }
}