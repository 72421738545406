import { Injectable } from "@angular/core";
import moment from "moment";
import { DeviceDetectorService } from "ngx-device-detector";
import { AuthService } from "../../login/services/auth.services";

@Injectable({
    providedIn: "root"
})
export class Utils {
    constructor(
        public authService: AuthService,
        private deviceService: DeviceDetectorService,
    ) { }

    public async saveDataAudit(status: string, description: string, extra: any = {}) {
         const dataInStorage: any = localStorage ? JSON.parse(localStorage.getItem('jsonAuditAzhBeneficiaries') as any) || [] : [];
        let jsonAudit = [...dataInStorage];
        jsonAudit.push({
            "estatus": status,
            "description": description,
            "info": extra,
            "created_at": moment().format('YYYY-MM-DD HH:mm:ss.ms')
        });

        localStorage.setItem('jsonAuditAzhBeneficiaries', JSON.stringify(jsonAudit));
    }

    public saveAudit() {
        let jsonAudit = localStorage ? JSON.parse(localStorage.getItem('jsonAuditAzhBeneficiaries') as any) || [] : [];
        let json = {
            dataSet: [
                {
                    "estatus": "Exitoso",
                    "description": "Flujo de Auditoria",
                    "platform": "Portal Web - Beneficiario",
                    "raw_data": {
                        deviceInfo: this.deviceService.getDeviceInfo(),
                        data: jsonAudit
                    },
                    "created_at": moment().format('YYYY-MM-DD HH:mm:ss.ms')
                }
            ],
        }

        if (!jsonAudit.length) return;
        this.authService.postAudit(json).subscribe(() => { });
    }
}